import React from 'react';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { closeStatusMessage } from '../store/actions';

const styles = theme => ({
  snackbar: { position: "fixed" },
  snackbarContent: {
    width: 360,
  },
});

class InnerStatusMessage extends React.PureComponent {
  render() {
    const { classes } = this.props;  

    return (
      <Snackbar
        open={this.props.openStatusMessage}
        autoHideDuration={4000}
        onClose={this.props.onClose}
        ContentProps={{
          'aria-describedby': 'snackbar-fab-message-id',
          className: classes.snackbarContent,
        }}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.props.onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        message={this.props.statusMessage}
        className={classes.snackbar}
      />
    )
  }

}

const mapStateToProps = state => {
  return {
    openStatusMessage: state.openStatusMessage,
    statusMessage: state.statusMessage,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(closeStatusMessage());
    },
  }
}
const StatusMessage = connect(mapStateToProps, mapDispatchToProps)(InnerStatusMessage);

export default withStyles(styles)(StatusMessage);

